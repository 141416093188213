import { Component, OnInit } from '@angular/core'
import { GlobalNotification, GlobalState } from '../../app.state'
import { SidebarService } from './sidebar.service'
import { CxConsts } from '../../shared/typings/chameleonx.typings'
import { Router } from '@angular/router'

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  public isSidebarCollapsed: boolean

  public readonly SIDEBAR_PAGES: cxNavigationItem[] = [
    //@formatter:off

    {category: 'Overview'},
          {text: 'Dashboard',               active: true,   route: CxConsts.cxRoutingTable.DASHBOARD,                       iconClass: CxConsts.IconClasses.TH},
          {text: 'Activity Feed',           active: true,   route: CxConsts.cxRoutingTable.ACTIVITY_FEED,                   iconClass: CxConsts.IconClasses.LIST_ALT},

        {category: 'Health & Operations'},
          {text: 'Instances Dashboard',     active: true,   route: CxConsts.cxRoutingTable.INSTANCES_DASHBOARD,             iconClass: CxConsts.IconClasses.MICROCHIP},
          {text: 'Kafka Panel',             active: true,   route: CxConsts.cxRoutingTable.KAFKA_PANEL,                     imgIcon: 'confluent.cloud'},
          {text: 'MongoDB Analytics',       active: true,   route: CxConsts.cxRoutingTable.MONGODB_ANALYTICS,               imgIcon: 'cloud.mongodb.com'},

        {category: 'Manage'},
          {text: 'Akamai Customers',        active: true,   route: CxConsts.cxRoutingTable.AKAMAI_CUSTOMERS,                imgIcon: 'akamai.com'},
          {text: 'Tenants',                 active: true,   route: CxConsts.cxRoutingTable.CUSTOMERS,                       iconClass: CxConsts.IconClasses.BANK},
          {text: 'Instances',               active: true,   route: CxConsts.cxRoutingTable.CLUSTERS,                        iconClass: CxConsts.IconClasses.BOXES},
          {text: 'Databases',               active: true,   route: CxConsts.cxRoutingTable.DATABASES,                       iconClass: CxConsts.IconClasses.DATABASE},
          {text: 'Users',                   active: true,   route: CxConsts.cxRoutingTable.USERS,                           iconClass: CxConsts.IconClasses.ACCOUNT},
          {text: 'Users Permissions',       active: true,   route: CxConsts.cxRoutingTable.USERS_PERMISSIONS_GROUP,         iconClass: CxConsts.IconClasses.SETTINGS},

        {category: 'Backend Configurations'},
          {text: 'Edge Mirroring',          active: true,   route: CxConsts.cxRoutingTable.TRAFFIC_MIRRORING,               iconClass: CxConsts.IconClasses.OBJECT_UNGROUP},
          {text: 'URL Normalizer',          active: true,   route: CxConsts.cxRoutingTable.URL_NORMALIZER_VERSIONS,         iconClass: CxConsts.IconClasses.OBJECT_GROUP},

        {category: 'AI Insights'},
          {text: 'Vendor Hunting',          active: true,   route: CxConsts.cxRoutingTable.VENDOR_HUNTING,                  iconClass: CxConsts.IconClasses.SWORD},

        {category: 'Client Versions'},
          {text: 'Tenants Config',          active: true,   route: CxConsts.cxRoutingTable.CUSTOMER_CONFIG,                 iconClass: CxConsts.IconClasses.FORM},
          {text: 'Agent Versions',          active: true,   route: CxConsts.cxRoutingTable.AGENT_VERSIONS,                  iconClass: CxConsts.IconClasses.VERSION_CONTROL},
          {text: 'Agent Libraries',         active: true,   route: CxConsts.cxRoutingTable.AGENT_LIBRARIES,                 iconClass: CxConsts.IconClasses.JAVASCRIPT},
          {text: 'AH Rules',                active: true,   route: CxConsts.cxRoutingTable.AUDIENCE_HIJACKING_RULES,        iconClass: CxConsts.IconClasses.LIST},
          {text: 'AH Versions',             active: true,   route: CxConsts.cxRoutingTable.AUDIENCE_HIJACKING_VERSIONS,     iconClass: CxConsts.IconClasses.CLOUD},

        {category: 'Deployments & Release'},
          {text: 'Images',                  active: true,   route: CxConsts.cxRoutingTable.DEPLOYMENT_IMAGES,               iconClass: CxConsts.IconClasses.DOCKER},
          {text: 'Deployments',             active: true,   route: CxConsts.cxRoutingTable.DEPLOYMENTS,                     iconClass: CxConsts.IconClasses.SERVER},
          {text: 'Deployment Groups',       active: true,   route: CxConsts.cxRoutingTable.DEPLOYMENT_GROUPS,               iconClass: CxConsts.IconClasses.GROUP},
          {text: 'Deployment Releases',     active: true,   route: CxConsts.cxRoutingTable.DEPLOYMENT_RELEASES,             iconClass: CxConsts.IconClasses.BOOK_OPEN},

        {category: 'Informational'},
          {text: 'Event Groups',            active: true,   route: CxConsts.cxRoutingTable.EVENT_GROUPS,                    iconClass: CxConsts.IconClasses.NEWSPAPER},
          {text: 'Reports',                 active: true,   route: CxConsts.cxRoutingTable.REPORTS,                         iconClass: CxConsts.IconClasses.CHART},
          {text: 'Intelligence',            active: true,   route: CxConsts.cxRoutingTable.INTELLIGENCE,                    iconClass: CxConsts.IconClasses.FLASK},

        {category: 'Tools'},
          {text: 'AH Analytics',            active: true,   route: CxConsts.cxRoutingTable.AUDIENCE_HIJACKING_ANALYTICS,    iconClass: CxConsts.IconClasses.ANALYTICS},
          {text: 'Collected Inputs',        active: true,   route: CxConsts.cxRoutingTable.COLLECTED_INPUTS,                iconClass: CxConsts.IconClasses.POLL},
          {text: 'Tools',                   active: true,   route: CxConsts.cxRoutingTable.TOOLS,                           iconClass: CxConsts.IconClasses.GEARS},

        //@formatter:on
  ]

  constructor (private _state: GlobalState, private _sidebar: SidebarService, public _routes: Router) {
    this.isSidebarCollapsed = this._sidebar.isCollapsed
    this._state.subscribe(GlobalNotification.SIDEBAR_COLLAPSED, collapsed => this.isSidebarCollapsed = collapsed)
  }

  ngOnInit () {
    this._sidebar.init()
  }
}
