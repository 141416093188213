import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { UrlNormalizerProcessorService } from '../processors/url-normalizer-processor.service'

@Injectable()
export class UrlNormalizerService<T extends ExtendedURLNormalizerVersionsSchema = ExtendedURLNormalizerVersionsSchema> extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAllVersions (options: FilterArguments): Promise<CountedResultObject<T[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/un_versions?${queryString}`) as ResultObject<CountedResultObject<T[]>>
    return {
      count: response.count,
      success: response.success,
      data: UrlNormalizerProcessorService.process(response.data.data) as T[]
    }
  }

  async getVersionData (version: string): Promise<Array<StandardObject>> {
    const queryString = MethodsService.objectToQueryString({ version })
    const response = await this.get(`api/admin_api/un_versions/version_data?${queryString}`) as ResultObject<Array<StandardObject>>
    return response.data
  }

  async getMetadata (): Promise<URLNormalizerVersionsMetadata> {
    const response = await this.get(`api/admin_api/un_versions/metadata`) as ResultObject<URLNormalizerVersionsMetadata>
    return response.data
  }

  async setAvailable (version: string) {
    const body = JSON.stringify({ version })
    const response = await this.post(`api/admin_api/un_versions/set_available`, body, true, 'application/json')
    return response.success
  }

  async setUnavailable (version: string, comment: string, pages: string[]) {
    const body = JSON.stringify({ version, comment, pages })
    const response = await this.post(`api/admin_api/un_versions/set_unavailable`, body, true, 'application/json')
    return response.success
  }

  async setDefault (version: string) {
    const body = JSON.stringify({ version })
    const response = await this.post(`api/admin_api/un_versions/set_default`, body, true, 'application/json')
    return response.success
  }

  async setRollout (version: string) {
    const body = JSON.stringify({ version })
    const response = await this.post(`api/admin_api/un_versions/set_rollout`, body, true, 'application/json')
    return response.success
  }

  async addPages (version: string, pages: string[]): Promise<T> {
    const body = JSON.stringify({ version, pages })
    const response = await this.post(`api/admin_api/un_versions/add_pages`, body, true, 'application/json')
    return UrlNormalizerProcessorService.process(response.data as T) as T
  }

  async removePages (version: string, pages: string[]): Promise<T> {
    const body = JSON.stringify({ version, pages })
    const response = await this.post(`api/admin_api/un_versions/remove_pages`, body, true, 'application/json')
    return UrlNormalizerProcessorService.process(response.data as T) as T
  }

}

