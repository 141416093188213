/** Core **/
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

/** Main App **/
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

/** Global Services **/
import { GlobalState } from './app.state'
import { AuthService } from './services/auth/auth.service'
import { UserDataService } from './services/user-data/user-data.service'
import { DragulaModule, DragulaService } from 'ng2-dragula/dist'
import { SidebarService } from './layout/sidebar/sidebar.service'

//layout
import { NavbarComponent } from './layout/navbar/navbar.component'
import { FooterComponent } from './layout/footer/footer.component'
import { SidebarComponent } from './layout/sidebar/sidebar.component'

//pages
import { PIDashboardComponent } from './pages/pi-dashboard/dashboard.component'
import { PIActivityFeedComponent } from './pages/pi-activity-feed/activity-feed.component'
import { PIAkamaiCustomersComponent } from './pages/pi-akamai-customers/akamai-customers.component'
import { PITenantsComponent } from './pages/pi-tenants/pi-tenants.component'
import { PIUsersComponent } from './pages/pi-users/users.component'
import { PIInstancesComponent } from './pages/pi-instances/pi-instances.component'
import { PIDatabasesComponent } from './pages/pi-databases/pi-databases.component'
import { PICustomerConfigComponent } from './pages/pi-customer-config/customer-config.component'
import { PIAgentLibrariesComponent } from './pages/pi-agent-libraries/agent-libraries.component'
import { PIAgentVersionsComponent } from './pages/pi-agent-versions/agent-versions.component'
import {
  PIAudienceHijackingAnalyticsComponent
} from './pages/pi-audience-hijacking-analytics/audience-hijacking-analytics.component'
import {
  PIAudienceHijackingRulesComponent
} from './pages/pi-audience-hijacking-rules/audience-hijacking-rules.component'
import {
  PIAudienceHijackingVersionsComponent
} from './pages/pi-audience-hijacking-versions/audience-hijacking-versions.component'
import { PILoginComponent } from './pages/pi-login/login.component'
import { PIToolsComponent } from './pages/pi-tools/tools.component'
import { PICollectedInputsComponent } from './pages/pi-collected-inputs/collected-inputs.component'
import { PIReportsComponent } from './pages/pi-reports/reports.component'
import { PIIntelligenceComponent } from './pages/pi-intelligence/pi-intelligence.component'
import { PIDeploymentImagesComponent } from './pages/pi-deployment-images/pi-deployment-images.component'
import { PIDeploymentsComponent } from './pages/pi-deployments/pi-depyloyments.component'
import { PIDeploymentGroupsComponent } from './pages/pi-deployment-groups/pi-depyloyment-groups.component'
import { PIDeploymentReleasesComponent } from './pages/pi-deployment-releases/pi-depyloyment-releases.component'
import { PIUsersPermissionsGroupsComponent } from './pages/pi-users-groups/users-permissions-group.component'
import { PIQueuePanelComponent } from './pages/pi-queue-panel/pi-queue-panel.component'
import { PITrafficMirroringComponent } from './pages/pi-traffic-mirroring/traffic-mirroring.component'
import { PIInstancesDashboardComponent } from './pages/pi-instances-dashboard/pi-instances-dashboard.component'
import { PIKafkaPanelComponent } from './pages/pi-kafka-panel/pi-kafka-panel.component'
import {
  PIInstancesDashboardSubComponent
} from './pages/pi-instances-dashboard-sub/pi-instances-dashboard-sub.component'
import { PIURLNormalizerVersionsComponent } from './pages/pi-url-normalizer-versions/url-normalizer-versions.component'
import { PIVendorHuntingComponent } from './pages/pi-vendor-hunting/vendor-hunting.component'
import { PIMongoDBAnalyticsComponent } from './pages/pi-mongodb-analytics/pi-mongodb-analytics.component'
import { PiEventConsoleComponent } from './pages/pi-event-console/pi-event-console.component'
import { PiEventGroupComponent } from './pages/pi-event-group/pi-event-group.component'

//services
import { AkamaiCustomersService } from './services/api/services/akamai-customers.service'
import { PITenantsService } from './services/api/services/pi-tenants.service'
import { UsersService } from './services/api/services/users.service'
import { PIInstancesService } from './services/api/services/pi-instances.service'
import { PIDatabasesService } from './services/api/services/pi-databases.service'
import { CustomerConfigService } from './services/api/services/customer-config.service'
import { AgentVersionsService } from './services/api/services/agent-versions.service'
import { AgentLibrariesService } from './services/api/services/agent-libraries.service'
import { AudienceHijackingRulesService } from './services/api/services/audience-hijacking-rules.service'
import { AudienceHijackingVersionsService } from './services/api/services/audience-hijacking-versions.service'
import { AudienceHijackingAnalyticsService } from './services/api/services/audience-hijacking-analytics.service'
import { RwaspVersionsService } from './services/api/services/rwasp-versions.service'
import { RwaspModulesService } from './services/api/services/rwasp-modules.service'
import { LogsService } from './services/api/services/logs.service'
import { PIToolsService } from './services/api/services/tools.service'
import { PICollectedInputsService } from './services/api/services/collected-inputs.service'
import { PiIntelligenceService } from './services/api/services/pi-intelligence.service'
import { PIDeploymentImagesService } from './services/api/services/pi-deployment-images.service'
import { PiDeploymentGroupsService } from './services/api/services/pi-deployment-groups.service'
import { PiDeploymentReleasesService } from './services/api/services/pi-deployment-releases.service'
import { UsersPermissionsGroupService } from './services/api/services/users-permissions-group.service'
import { PiQueuePanelService } from './services/api/services/pi-queue-panel.service'
import { PITrafficMirroringService } from './services/api/services/traffic-mirroring.service'
import { PIInstancesDashboardService } from './services/api/services/pi-instances-dashboard.service'
import { PiKafkaPanelService } from './services/api/services/pi-kafka-panel.service'
import { UrlNormalizerService } from './services/api/services/url-normalizer.service'
import { PiMongodbAnalyticsService } from './services/api/services/pi-mongodb-analytics.service'
import { PiEventConsoleService } from './services/api/services/pi-event-console.service'

/** Modules **/
import { ChartsModule } from 'ng2-charts'
import { Ng5SliderModule } from 'ng5-slider'
import { Ng2OdometerModule } from 'ng2-odometer'
import { ClickOutsideModule } from 'ng-click-outside'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule } from '@angular/material'
import { CxTagComponent } from './shared/components/cx-tag/cx-tag.component'
import { DummyComponent } from './shared/components/dummy-component/dummy-component'
import { NoDataComponent } from './shared/components/no-data/no-data.component'
import { FavIconComponent } from './shared/components/fav-ico/fav-icon.component'
import { CxBrowserComponent } from './shared/components/cx-browser/cx-browser.component'
import { CxCountryComponent } from './shared/components/cx-country/cx-country.component'
import { CxFullTagComponent } from './shared/components/cx-full-tag/cx-full-tag.component'
import { CxSpinnerComponent } from './shared/components/cx-spinner/cx-spinner.component'
import { CxTogglerComponent } from './shared/components/cx-toggler/cx-toggler.component'
import { CxWorldMapComponent } from './shared/components/cx-world-map/cx-world-map.component'
import { CxBarChartComponent } from './shared/components/cx-bar-chart/cx-bar-chart.component'
import { CxPieChartComponent } from './shared/components/cx-pie-chart/cx-pie-chart.component'
import { CxLineChartComponent } from './shared/components/cx-line-chart/cx-line-chart.component'
import { CxDoughnutChartComponent } from './shared/components/cx-doughnut-chart/cx-doughnut-chart.component'
import { CxDebounce } from './shared/directives/cx-debounce'
import { PITableJiraComponent } from './shared/components/jira/pi-table-jira.component'
import { PITableClockComponent } from './shared/components/table-clock/pi-table-clock.component'
import { PITrendChartComponent } from './shared/components/pi-trend-chart/pi-trend-chart.component'
import { PIImagesEnvironmentComponent } from './shared/components/pi-images-environment/pi-images-environment.component'
import { PIVersionComponent } from './shared/components/pi-version/pi-version.component'
import { PIInstanceIdComponent } from './shared/components/pi-instance-id/pi-instance-id.component'
import { PITextIdComponent } from './shared/components/pi-text-id/pi-text-id.component'
import { PINgxTrendComponent } from './shared/components/pi-ngx-trend/pi-ngx-trend.component'
import { PIDatabaseIdComponent } from './shared/components/pi-database-id/pi-database-id.component'
import {
  EventGroupsTableComponent
} from './shared/components/event-group/pi-event-groups-table/event-groups-table.component'
import { EventGroupDataComponent } from './shared/components/event-group/pi-event-group-data/event-group-data.component'
import {
  EventGroupHeaderComponent
} from './shared/components/event-group/event-group-0-header/event-group-header.component'
import {
  EventGroupAlertSummaryComponent
} from './shared/components/event-group/event-group-1-alert-summary/event-group-alert-summary.component'
import {
  EventGroupImpactedComponent
} from './shared/components/event-group/event-group-2-impacted/event-group-impacted.component'
import {
  EventGroupSourceDestinationComponent
} from './shared/components/event-group/event-group-3-source-destination/event-group-source-destination.component'
import { PiTimeseriesBarChartComponent } from './shared/components/ag-chart/pi-timeseries-bar-chart.component'

const PAGES = [
  PILoginComponent,
  PIDashboardComponent,
  PIActivityFeedComponent,
  PIAkamaiCustomersComponent,
  PITenantsComponent,
  PIInstancesComponent,
  PIDatabasesComponent,
  PIUsersComponent,
  PIInstancesDashboardComponent,
  PIInstancesDashboardSubComponent,
  PIUsersPermissionsGroupsComponent,
  PICustomerConfigComponent,
  PIDeploymentImagesComponent,
  PIDeploymentsComponent,
  PIDeploymentReleasesComponent,
  PIDeploymentGroupsComponent,
  PIAgentVersionsComponent,
  PIAgentLibrariesComponent,
  PIAudienceHijackingRulesComponent,
  PIAudienceHijackingVersionsComponent,
  PIIntelligenceComponent,
  PIQueuePanelComponent,
  PIKafkaPanelComponent,
  PIToolsComponent,
  PICollectedInputsComponent,
  PIReportsComponent,
  PITrafficMirroringComponent,
  PIAudienceHijackingAnalyticsComponent,
  PIURLNormalizerVersionsComponent,
  PIVendorHuntingComponent,
  PIMongoDBAnalyticsComponent,
  PiEventConsoleComponent,
  PiEventGroupComponent
]

@NgModule({
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule
  ],
  declarations: [
    ...PAGES,
    AppComponent,
    DummyComponent,
    CxDebounce,
    CxTagComponent,
    NoDataComponent,
    NavbarComponent,
    FooterComponent,
    FavIconComponent,
    SidebarComponent,
    CxSpinnerComponent,
    CxCountryComponent,
    CxFullTagComponent,
    CxTogglerComponent,
    CxBrowserComponent,
    CxBarChartComponent,
    CxPieChartComponent,
    CxWorldMapComponent,
    CxLineChartComponent,
    CxDoughnutChartComponent,
    PITableJiraComponent,
    PITableClockComponent,
    PITrendChartComponent,
    PIImagesEnvironmentComponent,
    PIVersionComponent,
    PIInstanceIdComponent,
    PITextIdComponent,
    PINgxTrendComponent,
    PIDatabaseIdComponent,
    EventGroupsTableComponent,
    EventGroupDataComponent,
    EventGroupHeaderComponent,
    EventGroupAlertSummaryComponent,
    EventGroupImpactedComponent,
    EventGroupSourceDestinationComponent,
    PiTimeseriesBarChartComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    ChartsModule,
    BrowserModule,
    DragulaModule,
    Ng5SliderModule,
    AppRoutingModule,
    ClickOutsideModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    Ng2OdometerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule
  ],
  providers: [
    LogsService,
    GlobalState,
    AuthService,
    UsersService,
    SidebarService,
    PIToolsService,
    DragulaService,
    UserDataService,
    AgentVersionsService,
    AgentLibrariesService,
    AudienceHijackingRulesService,
    AudienceHijackingVersionsService,
    AudienceHijackingAnalyticsService,
    RwaspVersionsService,
    RwaspModulesService,
    PIInstancesService,
    PiDeploymentGroupsService,
    PiDeploymentReleasesService,
    UsersPermissionsGroupService,
    PiIntelligenceService,
    PIDatabasesService,
    PITenantsService,
    CustomerConfigService,
    AkamaiCustomersService,
    PICollectedInputsService,
    PIDeploymentImagesService,
    PiQueuePanelService,
    PITrafficMirroringService,
    PIInstancesDashboardService,
    PiKafkaPanelService,
    UrlNormalizerService,
    PiMongodbAnalyticsService,
    PiEventConsoleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
